import React from 'react'
import HeroText from '../../components/hero-text'
import styles from './science-hero.module.scss'

const ScienceHero = () => {
    return (
        <section className={styles.main}>
            <img src="/dna.svg" alt="DNA" className={styles.dna} />
            <div className="container mb-12 lg:mb-20 md:flex justify-between items-center">
                <HeroText>
                    <h1>Natural evolved <strong>technology.</strong></h1>
                    <p>After millions of years of co-evolution with multi-cellular organisms, the active molecule of INV-102 has become highly effective at triggering targets associated with the DNA damage response.</p>
                </HeroText>
                <div className={styles.heroImage}>
                    <img src="/science-hero.png" alt="Invirsa Science"/>
                </div>
            </div>
            <div className={styles.wave}>
                <img src="/science-wave.svg" alt="Wave"/>
            </div>

        </section>
    )
}

export default ScienceHero