import React from 'react'
import styles from './science-collaborators.module.scss'

const ScienceCollaborators = () => {
    return (
        <section className={styles.collaborators}>
            <div className="container">
                <h3 className="text-center">Collaborators</h3>
                <div className="mt-12 mb-24 flex justify-center">
                    <img src="nationwide.png" alt="Nationwide Children's"/>
                </div>
            </div>
        </section>
    )
}

export default ScienceCollaborators