import React from 'react'
import styles from './science-platform.module.scss'

const SciencePlatform = () => {
    return (
        <section className="mt-24">
            <img src="/gray-wave-top.svg" alt="" className={styles.wave}/>
            <div className={styles.main}>
                <div className="container">
                    <h2><strong>One Platform –</strong><br />Multiple Indications</h2>
                    <p className="big">Invirsa is planning to enter clinical trials by 2022. However, given the DNA stabilizing properties of INV-102, Invirsa is aiming to rapidly expand initial studies into other indications including viral pneumonia and dry eye.</p>

                    <div className="grid md:grid-cols-3 mt-24 md:gap-24">
                        <div className={styles.indication}>
                            <div className={styles.icon}>
                                <img src="eye-icon.svg" alt="Ocular Indication" className="mb-3"/>
                            </div>
                            <h3>Ocular (Primary)</h3>
                            <p>INV-102, in non-clinical studies, has been shown to significantly improve recovery and reduce inflammation from DNA damage caused by sulfur mustard gas injury and  adenovirus infection.</p>
                        </div>
                        <div className={styles.indication}>
                            <div className={styles.icon}>
                                <img src="lung-icon.svg" alt="Ocular Indication" className="mb-3"/>
                            </div>
                            <h3>Respiratory</h3>
                            <p>Invirsa is currently collaborating with Nationwide Children’s to study INV-102 for acute, viral lung infection.</p>
                        </div>
                        <div className={styles.indication}>
                            <div className={styles.icon}>
                                <img src="skin-icon.svg" alt="Ocular Indication" className="mb-3"/>
                            </div>
                            <h3>Dermatological</h3>
                            <p>Our skin is constantly faced with single-strand DNA breaks caused by UV radiation. Invirsa has hypothesized that INV-102 is a perfect candidate to treat surface UV injury whether caused by the sun or radiation therapy for cancer. Invirsa has multiple collaborations exploring this opportunity.</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="/gray-wave-bottom.svg" alt="" className={styles.wave}/>
        </section>
    )
}

export default SciencePlatform