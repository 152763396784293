import React from 'react'
import styles from "./science-process.module.scss";

const ScienceProcess = () => {
    return (
        <section className={`container ${styles.main}`}>
            <div className="flex flex-col-reverse flex-wrap md:grid grid-cols-2 mb-12 md:mb-24 gap-4 items-center">
                <div className={styles.text}>
                    <h2 className="text-gray mb-4">INV-102 activates p53: The ultimate cell regulator</h2>
                    <p>INV-102’s primary effect is short term activation of p53. In times of crisis, p53 guides damaged cells to take the appropriate action. By slowing the cell cycle and stabilizing repair pathways, p53 gives the cell time to repair its DNA, but it can also deem the cell unrepairable, and thus initiate apoptosis.</p>
                </div>
                <div className={styles.icon}>
                    <img src="/dna-stopwatch.svg" alt="Invirsa Science"/>
                </div>
            </div>
            <div className="flex flex-col md:grid grid-cols-2 mb-12 md:mb-24 gap-4 items-center">
                <div className={styles.icon}>
                    <img src="/perfect-pulse.svg" alt="Invirsa Science"/>
                </div>
                <div className={styles.text}>
                    <h2 className="text-gray mb-4">The perfect pulse</h2>
                    <p>Sustained high levels of p53 can lead to unnecessary apoptosis. However, INV-102 creates a predictable, pulsatile increase in p53, allowing for optimum DNA repair without causing unnecessary cell death.</p>
                </div>
            </div>
            <div className="flex flex-col-reverse flex-wrap md:grid grid-cols-2 gap-4 items-center justify-self-center">
                <div className={styles.text}>
                    <h2 className="text-gray mb-4">Decreased NF-κB means less inflammation</h2>
                    <p>Cellular injuries often cause a spike in NF-κB production leading to a multi-cellular chain reaction that causes inflammation. However, because of p53’s antagonistic relationship with NF-κB, INV-102 also limits inflammation at the site of the injury, further improving cell stability.</p>
                </div>
                <div className={styles.icon}>
                    <img src="/chain-reaction.svg" alt="Invirsa Science"/>
                </div>
            </div>
        </section>
    )
}

export default ScienceProcess