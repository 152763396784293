import React from 'react'
import MainLayout from '../layout/main-layout'
import ScienceHero from '../fragments/science/science-hero'
import ScienceProcess from '../fragments/science/science-process'
import SciencePlatform from '../fragments/science/science-platform'
import ScienceCollaborators from '../fragments/science/science-collaborators'

const Science = () => {
    return (
        <MainLayout>
            <ScienceHero></ScienceHero>
            <ScienceProcess></ScienceProcess>
            <SciencePlatform></SciencePlatform>
            <ScienceCollaborators></ScienceCollaborators>
        </MainLayout>
    )
}

export default Science